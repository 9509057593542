import { createTheme, Theme } from "@mui/material/styles";

// Default theme in the Gigamatch application
let gmTheme : Theme = createTheme({
  palette: {
    primary: {
      main: "#fff200",
      light: "#ffface",
      dark: "#e2d971",
    },
    secondary: {
      main: "#ffa600",
      light: "#ffd57f",
      dark: "#cc8f33",
    },
    background: {
      paper: '#fefefe',
      default: '#fefefe',
    }
  },
  typography: {
    h1: {
      fontWeight: 800,
      fontSize: "4rem",
      lineHeight: 1.5,
    },
    h2: {
      fontWeight: 800,
      fontSize: "3rem",
      lineHeight: 1.3,
    },
    h3: {
      fontWeight: 800,
      fontSize: "2.5rem",
      lineHeight: 1.3,
    },
    h4: {
      fontWeight: 600,
      fontSize: "2rem",
      lineHeight: 1.3,
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: 1.3,
    },
    h6: {
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: 1.2,
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: 1.2,
      letterSpacing: "0.009375em",
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "0.7rem",
      lineHeight: 1.2,
      letterSpacing: "0.009375em",
    },
    body1: {
      fontWeight: 400,
      fontSize: "0.8rem",
      lineHeight: 1.6,
      letterSpacing: "0.009375em",
    },
    body2: {
      fontWeight: 400,
      fontSize: "0.7rem",
      lineHeight: 1.6,
      letterSpacing: "0.009375em",
    },
    button: {
      fontWeight: 600,
      fontSize: "0.9rem",
      fontVariant: 'small-caps',
      lineHeight: 1.6,
      letterSpacing: "0.009375em",
      textTransform: "none",
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.8rem",
      lineHeight: 1.6,
      letterSpacing: "0.009375em",
      textTransform: "none",
    },
    overline: {
      fontWeight: 500,
      fontSize: "1rem",
      lineHeight: 1.6,
      letterSpacing: "0.009375em",
      textTransform: "none",
    },
  },
});

export default gmTheme;
