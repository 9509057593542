import { Link } from 'react-router-dom';


type InternalLinkProps = {
  path: string;   // The path within the application to link to
  children: React.ReactNode | React.ReactNode[];
}


// Render a link to a page within the application.
export default function InternalLink({ path, children } : InternalLinkProps)
{
  return (
    <Link to={path} style={ {textDecoration: 'none', color: 'inherit'} }>
      {children}
    </Link>
  );
}
