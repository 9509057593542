import { Typography } from "@mui/material";
import SupportPage, { ParagraphContainer, SectionTitleContainer } from "./SupportPage"


const title : string = "Privacy policy";
const lastUpdatedText : string = "Last updated on October 18, 2022";
const introText : string = "This privacy policy describes how Gigamatch handles yor personal data. More precisley, it describes";
const introItems : string[] =
  [
    "why, when, and from where we collect personal data",
    "how personal data are stored",
    "how personal data may be shared outside Gigamatch",
    "your rights and choices"
  ];

const collectionSectionTitle : string = "Collection of personal data";
const collectionSectionText : string = "Leverage agile frameworks to provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition. Organically grow the holistic world view of disruptive innovation via workplace diversity and empowerment.";

const usageSectionTitle : string = "Usage of personal data";
const usageSectionText : string = "Bring to the table win-win survival strategies to ensure proactive domination. At the end of the day, going forward, a new normal that has evolved from generation X is on the runway heading towards a streamlined cloud solution. User generated content in real-time will have multiple touchpoints for offshoring.";

const protectionSectionTitle : string = "Protection of personal data";
const protectionSectionText : string = "Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps. Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line.";

const rightsSectionTitle : string = "Your data, your rights";
const rightsSectionText : string = "Podcasting operational change management inside of workflows to establish a framework. Taking seamless key performance indicators offline to maximise the long tail. Keeping your eye on the ball while performing a deep dive on the start-up mentality to derive convergence on cross-platform integration.";

const consentSectionTitle : string = "Consent";
const onsentSectionText : string = "By using Gigamatch you agree to this privacy policy.";


export default function PrivacyPolicyPage()
{
  return (
    <SupportPage text={title}>

      <ParagraphContainer>
        <Typography variant="body2"><i>{lastUpdatedText}</i></Typography>
      </ParagraphContainer>

      <ParagraphContainer>
        <Typography variant="body1">{introText}</Typography>
      </ParagraphContainer>
      <ul>
        {introItems.map((item) => (<li>{item}</li>))}
      </ul>

      <SectionTitleContainer>
        <Typography variant="h5">{collectionSectionTitle}</Typography>
      </SectionTitleContainer>
      <ParagraphContainer>
        <Typography variant="body1">{collectionSectionText}</Typography>
      </ParagraphContainer>

      <SectionTitleContainer>
        <Typography variant="h5">{usageSectionTitle}</Typography>
      </SectionTitleContainer>
      <ParagraphContainer>
        <Typography variant="body1">{usageSectionText}</Typography>
      </ParagraphContainer>

      <SectionTitleContainer>
        <Typography variant="h5">{protectionSectionTitle}</Typography>
      </SectionTitleContainer>
      <ParagraphContainer>
        <Typography variant="body1">{protectionSectionText}</Typography>
      </ParagraphContainer>

      <SectionTitleContainer>
        <Typography variant="h5">{rightsSectionTitle}</Typography>
      </SectionTitleContainer>
      <ParagraphContainer>
        <Typography variant="body1">{rightsSectionText}</Typography>
      </ParagraphContainer>

      <SectionTitleContainer>
        <Typography variant="h5">{consentSectionTitle}</Typography>
      </SectionTitleContainer>
      <ParagraphContainer>
        <Typography variant="body1">{onsentSectionText}</Typography>
      </ParagraphContainer>

    </SupportPage>
  );
}
