// Properties for the ExternalLink element
type ExternalLinkProps = {
  target: string; // The target outside the application to link to
  children: React.ReactNode | React.ReactNode[];
}


// Render an <a> tag for a target outside the application.
export default function ExternalLink({ target, children } : ExternalLinkProps)
{
  return (
      <a href={target} target="_blank" rel="noreferrer" style={ {textDecoration: 'none', color: 'inherit'} }>
        {children}
      </a>
  );
}
