import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import HomePage from './page/home/HomePage';
import AboutPage from './page/support/AboutPage';
import CookiePolicyPage from './page/support/CookiePolicyPage';
import FaqPage from './page/support/FaqPage';
import HowItWorksPage from './page/support/HowItWorksPage';
import NotFoundPage from './page/common/NotFoundPage';
import PrivacyPolicyPage from './page/support/PrivacyPolicyPage';
import TermsOfUsePage from './page/support/TermsOfUsePage';

import { UserSessionProvider } from './session/SessionContext';
import { routes } from './route/Routes';
import gmTheme from './theme/gmTheme';


// Render the main element in the Gigamatch application.
// This element specifies the default theme, sets up the CSS Baseline and
// provides the routes for the application.
export default function App()
{
  return (
    <ThemeProvider theme={gmTheme}>
      <CssBaseline/>
      <UserSessionProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path={routes.about} element={<AboutPage/>} />
            <Route path={routes.cookiePolicy} element={<CookiePolicyPage/>} />
            <Route path={routes.faq} element={<FaqPage/>} />
            <Route path={routes.howItWorks} element={<HowItWorksPage/>} />
            <Route path={routes.privacyPolicy} element={<PrivacyPolicyPage/>} />
            <Route path={routes.termsOfUse} element={<TermsOfUsePage/>} />
            <Route path="*" element={<NotFoundPage/>} />
          </Routes>
        </BrowserRouter>
      </UserSessionProvider>
    </ThemeProvider>
  );
}
