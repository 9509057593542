export type RouteProps = {
  // Support pages
  about : string;
  cookiePolicy : string;
  faq : string;
  privacyPolicy : string;
  termsOfUse : string;

  // Information pages
  howItWorks : string;
  artistsInfo : string;
  venuesInfo : string;
  fansInfo : string;
  gigsInfo : string;

  // News pages
  news : string;
  events : string;
}

// The routes used in the application
export const routes : RouteProps = {
  about: "/about",
  cookiePolicy: "/cookie",
  faq: "/faq",
  privacyPolicy: "/privacy",
  termsOfUse: "/terms",

  howItWorks: "/how",
  artistsInfo: "/artists",
  venuesInfo: "/venues",
  fansInfo: "/fans",
  gigsInfo: "/gigs",

  news: "/news",
  events: "/events",
};