import { UserSession } from './UserSession';

// Keys used in session storage
const userNameKey = "gmatchUser";
const tokenKey = "gmatchToken";


// Load any UserSession stored in session storage.
export function loadUserSession() : UserSession
{
  return {
    userName: sessionStorage.getItem(userNameKey),
    token: sessionStorage.getItem(tokenKey),
    message: null
  };
};


// Store a session user name in session storage.
export function storeSessionUserName(userName: string | null) : void
{
  if (userName !== null)
    sessionStorage.setItem(userNameKey, userName);
};


// Store a session token in session storage.
export function storeSessionToken(token: string | null) : void
{
  if (token !== null)
    sessionStorage.setItem(tokenKey, token);
};
