import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import ApplicationBar from '../../component/common/AppBar';
import Footer from '../../component/common/Footer';
import NewsSection from './NewsSection';
import UpcomingEventsSection from './UpcomingEvents';

import { routes } from '../../route/Routes';


const title : string = "Experience unforgettable moments";

const artistsTitle : string = "Artists";
const artistsText : string = "Expose your talent and locate venues for your next performance";

const venuesTitle : string = "Venues";
const venuesText : string = "Promote your events and find artists for future gigs";

const fansTitle : string = "Fans";
const fansText : string = "Follow your favourites and check what's going on at your local places";

const gigsTitle : string = "Gigs";
const gigsText : string = "Search for your next unforgettable moment";

const buttonTextHowItWorks : string ="Find out how";
const buttonTextExplore : string ="Explore";

// Style for the entire page
const PageContainer = styled('div')
(
  ({ theme }) => ({
    color: 'black',
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  })
);

// The page header has an image background that covers the entire screen
const PageHeaderContainer = styled('div')
(
  ({ theme }) => ({
    height: '300px',
    backgroundImage: 'url("/img/concert.jpg")',
    backgroundRepeat: 'none',
    backgroundSize: 'cover',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  })
);

// The page title is displayed inside the header with outlined text for contrast with
// the image background
const PageTitleContainer = styled('div')
(
  {
    color: 'white',
    WebkitTextStrokeWidth: 1,
    WebkitTextStrokeColor: '#999999',
  }
);

// The explore container holds a row of items with links to explore the main
// parts of the application
const ExploreContainer = styled('div')
(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  })
);

// An explore item's size is adjusted with the screen width, it's contents
// are a column of texts
const ExploreItemContainer = styled('div')
(
  ({ theme }) => ({
    width: '280px',
    [theme.breakpoints.down('xl')]: {
      width: '250px',
    },
    [theme.breakpoints.down('lg')]: {
      width: '200px',
    },
    [theme.breakpoints.down('md')]: {
      width: '180px',
    },
    minHeight: '100px',
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  })
);

// The explore item title should stay at top of the item container
const ExploreItemTitle = styled('div')
(
  {
   flexGrow: 0,
  }
);

// The explore item text's size varies with the container's size
const ExploreItemText = styled('div')
(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
    flexGrow: 1,
  })
);

// The explore item buttton should stay at bottom of the item container
const ExploreItemButton = styled('div')
(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
    flexGrow: 0,
  })
);


function PageHeader()
{
  let navigate = useNavigate();
  let clickAction = () =>  navigate(routes.howItWorks);

  return (
    <PageHeaderContainer>
      <PageTitleContainer>
        <Typography variant="h2">{title}</Typography>
      </PageTitleContainer>
      <Button
        variant="contained"
        onClick={clickAction}>
          {buttonTextHowItWorks}
      </Button>
    </PageHeaderContainer>
  );
}

function ExploreSection()
{
  return (
    <ExploreContainer>
      <ExploreItem title={artistsTitle} text={artistsText} link={routes.artistsInfo}/>
      <ExploreItem title={venuesTitle} text={venuesText} link={routes.venuesInfo}/>
      <ExploreItem title={fansTitle} text={fansText} link={routes.fansInfo}/>
      <ExploreItem title={gigsTitle} text={gigsText} link={routes.gigsInfo}/>
    </ExploreContainer>
  );
}

// An explore item ha a title a text and a link to which the button click lead to
type ExploreItemProps = {
  title: string;
  text: string;
  link: string;
}

function ExploreItem( {title, text, link} : ExploreItemProps )
{
  let navigate = useNavigate();
  let clickAction = () =>  navigate(link);

  return (
    <ExploreItemContainer>
      <ExploreItemTitle>
        <Typography variant="h5">{title}</Typography>
      </ExploreItemTitle>
      <ExploreItemText>
        <Typography variant="subtitle1">{text}</Typography>
      </ExploreItemText>
      <ExploreItemButton>
        <Button
          size="small"
          variant="outlined"
          sx={{color: 'inherit', borderColor: theme => theme.palette.primary.dark }}
          onClick={clickAction}>
            {buttonTextExplore}
        </Button>
      </ExploreItemButton>
    </ExploreItemContainer>
  );
}

export default function HomePage()
{
  return (
    <>
      <ApplicationBar/>
      <PageContainer>
        <PageHeader/>
        <ExploreSection/>
        <UpcomingEventsSection/>
        <NewsSection/>
      </PageContainer>
      <Footer/>
    </>
  );
}
