import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

import BasePage from "../common/BasePage"
import { TextAndChildren } from '../../type/BaseTypes';


// Style for the entire page
const PageContainer = styled('div')
(
  ({ theme }) => ({
    color: "black",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  })
);

// Style for the page title
const TitleContainer = styled('div')
(
  ({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
  })
);

// Style for the page content
const ContentContainer = styled('div')
(
  ({ theme }) => ({
    marginBottom: theme.spacing(5),
  })
);

// Style for a section title within the page
export const SectionTitleContainer = styled('div')
(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  })
);

// Style for a paragraph within the page
export const ParagraphContainer = styled('div')
(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
    maxWidth: theme.breakpoints.values.md
  })
);

// Style for a paragraph within the page
export const ImageContainer = styled('img')
(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
    maxWidth: theme.breakpoints.values.md
  })
);


// Render a support page, which has a page title and additional contents as children
export default function SupportPage({text, children} : TextAndChildren)
{
  return (
    <BasePage>
      <PageContainer>
        <TitleContainer>
          <Typography variant="h3">{text}</Typography>
        </TitleContainer>
        <ContentContainer>
          {children}
        </ContentContainer>
      </PageContainer>
    </BasePage>
  );
}
