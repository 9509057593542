import { Typography } from "@mui/material";
import SupportPage, { ParagraphContainer, SectionTitleContainer } from "./SupportPage"

const title : string = "Terms of use";
const definitionsTitle : string = "Definitions";
const definitionsText : string =
  "The Gigamatch website located at gigamatch.se is a copyrighted work belonging to Gigamatch. Certain features of the Site may be subject to additional guidelines, terms, or rules, which will be posted on the Site in connection with such features.";

const accountsTitle : string = "Accounts";
const accountsText : string =
  "Success is not final, failure is not fatal: it is the courage to continue that counts. You have enemies? Good. That means you've stood up for something, sometime in your life. Men occasionally stumble over the truth, but most of them pick themselves up and hurry off as if nothing had happened. Tact is the ability to tell someone to go to hell in such a way that they look forward to the trip.";

const accessTitle : string = "Access";
const accessText : string =
"The best argument against democracy is a five-minute conversation with the average voter. A lady came up to me one day and said 'Sir! You are drunk', to which I replied 'I am drunk today madam, and tomorrow I shall be sober but you will still be ugly. Never give in. Never give in. Never, never, never, never—in nothing, great or small, large or petty—never give in, except to convictions of honour and good sense. Never yield to force. Never yield to the apparently overwhelming might of the enemy.";

const contentTitle : string = "User content";
const contentText : string =
"The greatest lesson in life is to know that even fools are right sometimes. If you cannot read all your books...fondle them---peer into them, let them fall open where they will, read from the first sentence that arrests the eye, set them back on the shelves with your own hands, arrange them on your own plan so that you at least know where they are. Let them be your friends; let them, at any rate, be your acquaintances.";

const liabilityTitle : string = "Limitation on Liability";
const liabilityText : string =
"To each there comes in their lifetime a special moment when they are figuratively tapped on the shoulder and offered the chance to do a very special thing, unique to them and fitted to their talents. What a tragedy if that moment finds them unprepared or unqualified for that which could have been their finest hour. Continuous effort - not strength or intelligence - is the key to unlocking our potential. We make a living by what we get. We make a life by what we give.";
  

export default function TermsofUsePage()
{
  return (
    <SupportPage text={title}>

      <SectionTitleContainer>
        <Typography variant="h5">{definitionsTitle}</Typography>
      </SectionTitleContainer>
      <ParagraphContainer>
        <Typography variant="body1">{definitionsText}</Typography>
      </ParagraphContainer>

      <SectionTitleContainer>
        <Typography variant="h5">{accountsTitle}</Typography>
      </SectionTitleContainer>
      <ParagraphContainer>
        <Typography variant="body1">{accountsText}</Typography>
      </ParagraphContainer>

      <SectionTitleContainer>
        <Typography variant="h5">{accessTitle}</Typography>
      </SectionTitleContainer>
      <ParagraphContainer>
        <Typography variant="body1">{accessText}</Typography>
      </ParagraphContainer>

      <SectionTitleContainer>
        <Typography variant="h5">{contentTitle}</Typography>
      </SectionTitleContainer>
      <ParagraphContainer>
        <Typography variant="body1">{contentText}</Typography>
      </ParagraphContainer>

      <SectionTitleContainer>
        <Typography variant="h5">{liabilityTitle}</Typography>
      </SectionTitleContainer>
      <ParagraphContainer>
        <Typography variant="body1">{liabilityText}</Typography>
      </ParagraphContainer>

    </SupportPage>
  );
}
