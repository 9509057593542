import { styled } from '@mui/material/styles';

// A home page section displays its contents as a column
export const HomePageSectionContainer = styled('div')
(
  ({ theme }) => ({
    marginTop: theme.spacing(7),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  })
);

// A home page section title is centered inside its container
export const HomePageSectionTitleContainer = styled('div')
(
  {
    width: '100%',
    textAlign: 'center',
  }
);
