import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

import BasePage from "./BasePage"

const pageTitle : string = "Uh-oh";
const detailsText : string = "This page doesn't exist, either we made a mistake or perhaps you typed something wrong in the navigation.";
const goBackButtonText : string = "Go back";


// Style for the entire page
const PageContainer = styled('div')
(
  ({ theme }) => ({
    height: '300px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  })
);

// Style for the not-found image
const NotFoundImageContainer = styled('div')
(
  ({ theme }) => ({
    marginLeft: theme.spacing(1),
  })
);

// Style for the go back buttom
const GoBackButtonContainer = styled('div')
(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
  })
);


// Render the 'Page not found' page.
export default function NotFoundPage()
{
  let nav = useNavigate();

  return (
    <BasePage>
      <PageContainer>
        <Typography variant="h3">{pageTitle}</Typography>
        <NotFoundImageContainer>
          <img src="/img/confused-emoji.jpg" style={{ height: '60px'}}/>
        </NotFoundImageContainer>
        <Typography variant="subtitle1">{detailsText}</Typography>
        <GoBackButtonContainer>
          <Button
              size="small"
              variant="outlined"
              sx={{color: 'inherit', borderColor: theme => theme.palette.primary.dark}}
              onClick={() => {nav(-1);}}>
                {goBackButtonText}
            </Button>
          </GoBackButtonContainer>
      </PageContainer>
    </BasePage>
  );
}
