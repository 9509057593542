import { Typography } from "@mui/material";
import SupportPage, { ParagraphContainer, SectionTitleContainer } from "./SupportPage"


const title : string = "Frequently asked questions";

const q1Title : string = "What does it cost?";
const q1Text : string = "Each member contributes to a common fund based on the size of its overall economy. NATO’s common fund budget — $3.1 billion in 2021 — covers administrative costs and collective military infrastructure. One example of a common fund project: housing US military vehicles, tanks, and artillery at a Polish Air Force Base. The warehouse costs $360 million.";

const q2Title : string = "May I bring a friend?";
const q2Text : string = "May I Bring a Friend? is a 1964 book by Beatrice Schenk de Regniers. It tells the story of a well mannered boy who frequently gets invited to visit the king and queen. The first time he goes, he asks if he can bring a friend the next time he visits. When they say yes, he always brings different types of exotic animals who are also well mannered. The illustrator, Sir Beni Montresor, won the Caldecott Medal for this book for his jewel-like illustrations.";

const q3Title : string = "Do you even like music?";
const q3Text : string = "'Music affects deep emotional centers in the brain,' says Valorie Salimpoor, a neuroscientist at McGill University who studies the brain on music. 'A single sound tone is not really pleasurable in itself; but if these sounds are organized over time in some sort of arrangement, it’s amazingly powerful.' How powerful? In one of her studies, she and her colleagues hooked up participants to an fMRI machine and recorded their brain activity as they listened to a favorite piece of music. During peak emotional moments in the songs identified by the listeners, dopamine was released in the nucleus accumbens, a structure deep within the older part of our human brain.";

const q4Title : string = "What is the air speed of a swallow?";
const q4Text : string = "Well, the most obvious answer- you might think- is “An African or European swallow?” But this is, indeed, where film culture begins to diverge from real life. Because in actual fact, none of the 47 swallow species found on the continent directly bears its name. We might consider the West African swallow, or the South African Cave swallow, however a choice between these two would be completely arbitrary- and neither is sufficiently studied to provide the data we need.";

export default function FaqPage()
{
  return (
    <SupportPage text={title}>

      <SectionTitleContainer>
        <Typography variant="h5">{q1Title}</Typography>
      </SectionTitleContainer>
      <ParagraphContainer>
        <Typography variant="body1">{q1Text}</Typography>
      </ParagraphContainer>

      <SectionTitleContainer>
        <Typography variant="h5">{q2Title}</Typography>
      </SectionTitleContainer>
      <ParagraphContainer>
        <Typography variant="body1">{q2Text}</Typography>
      </ParagraphContainer>

      <SectionTitleContainer>
        <Typography variant="h5">{q3Title}</Typography>
      </SectionTitleContainer>
      <ParagraphContainer>
        <Typography variant="body1">{q3Text}</Typography>
      </ParagraphContainer>

      <SectionTitleContainer>
        <Typography variant="h5">{q4Title}</Typography>
      </SectionTitleContainer>
      <ParagraphContainer>
        <Typography variant="body1">{q4Text}</Typography>
      </ParagraphContainer>

    </SupportPage>
  );
}
