import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

import ArticleIcon from '@mui/icons-material/Article';
import CookieIcon from '@mui/icons-material/Cookie';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import InstagramIcon from "@mui/icons-material/Instagram";
import MailIcon from "@mui/icons-material/Mail";
import PeopleIcon from '@mui/icons-material/People';
import PolicyIcon from "@mui/icons-material/Policy";
import TwitterIcon from "@mui/icons-material/Twitter";

import ExternalLink from "../link/ExternalLink"
import InternalLink from "../link/InternalLink"
import { TextAndChildren } from '../../type/BaseTypes';


// Footer texts
const infoTitle : string = "Info";
const aboutLabel : string = "Who we are";
const aboutLink : string = "/about";
const howLabel : string = "How it works";
const howLink : string = "/how";
const faqLabel : string = "FAQ";
const faqLink : string = "/faq";

const contactTitle : string = "Contact";
const mailToLabel : string = "info@gigamatch.com";
const mailToLink : string = "mailto:gigamatch.contact@gmail.com";

const followTitle : string = "Follow";
const instagramLabel : string = "@Gigamatch0";
const instagramLink : string = "https://www.instagram.com/gigamatch0";
const twitterLabel : string = "@gigamatch";
const twitterLink : string = "https://twitter.com/DaBaus92";

const legalTitle : string = "Legal";
const termsLabel : string = "Terms of use";
const termsLink : string = "/terms";
const privacyPolicyLabel : string = "Privacy policy";
const privacyPolicyLink : string = "/privacy";
const cookiePolicyLabel : string = "Cookie policy";
const cookiePolicyLink : string = "/cookie";

const copyrightNotice : string = "\u00a9 2022 Gigamatch";


// Style for the entire footer, a column with rows, one for for the link
// columns and one for the copyright notice
const FooterContainer = styled('footer')
(
  ({ theme }) => ({
    color: "black",
    backgroundColor: theme.palette.secondary.main,
    display: "flex",
    flexDirection: "column",
  })
);


// Style for the row of footer columns containing the link groups
const FooterColumnsContainer = styled('div')
(
  {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around"
  }
);

// Style for the copyright notice row
const CopyrightContainer = styled('div')
(
  ({ theme }) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  })
);

// Style for each column of links in the footer
const FooterColumnContainer = styled('div')
(
  ({ theme }) => ({
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "left",
  })
);

// Style for footer column titles
const ColumnTitleContainer = styled('div')
(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
  })
);

// Style for each row in the footer columns
const ColumnRowContainer = styled('div')
(
  ({ theme }) => ({
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  })
);

// Style for icon labels in column rows, provides padding between the icon and
// its label
const IconLabelContainer = styled('div')
(
  ({ theme }) => ({
    paddingLeft: theme.spacing(1),
  })
);


// Render a footer column with a title text and the rows as children
function FooterColumn({ text, children } : TextAndChildren)
{
  return (
    <FooterColumnContainer>
      <ColumnTitleContainer>
        <Typography variant="h6">{text}</Typography>
      </ColumnTitleContainer>
      {children}
    </FooterColumnContainer>
  );
}

// Render a footer column row with an icon label text and the icon as children
function ColumnRow({ text, children } : TextAndChildren)
{
  return (
    <ColumnRowContainer>
      {children}
      <IconLabelContainer>
        <Typography variant="subtitle1">{text}</Typography>
      </IconLabelContainer>
    </ColumnRowContainer>
  );
}

// Properties for link elements in the footer
type FooterLinkProps = TextAndChildren & {
  target: string; // The link's target
}

// Render a link to an external target with an icon child and a link text
function FooterExternalLink({ target, text, children } : FooterLinkProps)
{
  return (
    <ExternalLink target={target}>
      <ColumnRow text={text} children={children}/>
    </ExternalLink>
  );
}

// Render a link to a path within the application with an icon child and a link text
function FooterInternalLink({ target, text, children } : FooterLinkProps)
{
  return (
    <InternalLink path={target}>
      <ColumnRow text={text} children={children}/>
    </InternalLink>
  );
}


// Render the standard page footer.
export default function Footer()
{
  return (
    <FooterContainer>
      <FooterColumnsContainer>

        <FooterColumn text={infoTitle}>
          <FooterInternalLink target={aboutLink} text={aboutLabel}>
            <PeopleIcon/>
          </FooterInternalLink>
          <FooterInternalLink target={howLink} text={howLabel}>
            <InfoIcon/>
          </FooterInternalLink>
          <FooterInternalLink target={faqLink} text={faqLabel}>
            <HelpIcon/>
          </FooterInternalLink>
        </FooterColumn>

        <FooterColumn text={contactTitle}>
          <FooterExternalLink target={mailToLink} text={mailToLabel}>
            <MailIcon/>
          </FooterExternalLink>
        </FooterColumn>

        <FooterColumn text={followTitle}>
          <FooterExternalLink target={instagramLink} text={instagramLabel}>
              <InstagramIcon/>
          </FooterExternalLink>
          <FooterExternalLink target={twitterLink} text={twitterLabel}>
            <TwitterIcon/>
          </FooterExternalLink>
        </FooterColumn>

        <FooterColumn text={legalTitle}>
          <FooterInternalLink target={termsLink} text={termsLabel}>
            <ArticleIcon/>
          </FooterInternalLink>
          <FooterInternalLink target={privacyPolicyLink} text={privacyPolicyLabel}>
            <PolicyIcon/>
          </FooterInternalLink>
          <FooterInternalLink target={cookiePolicyLink} text={cookiePolicyLabel}>
            <CookieIcon/>
          </FooterInternalLink>
        </FooterColumn>

      </FooterColumnsContainer>

      <CopyrightContainer>
        <Typography variant="body2">{copyrightNotice}</Typography>
      </CopyrightContainer>

    </FooterContainer>
  );
}
