import { Typography } from "@mui/material";
import SupportPage, { ImageContainer, ParagraphContainer } from "./SupportPage"

const title : string = "About Gigamatch";
const paragraph1 : string = "Gigamatch brings artists and venues together. Collaboratively administrate empowered markets via plug-and-play networks. Dynamically procrastinate B2C users after installed base benefits. Dramatically visualize customer directed convergence without revolutionary ROI. Efficiently unleash cross-media information without cross-media value. Quickly maximize timely deliverables for real-time schemas. Dramatically maintain clicks-and-mortar solutions without functional solutions.";
const paragraph2 : string = "Completely synergize resource taxing relationships via premier niche markets. Professionally cultivate one-to-one customer service with robust ideas. Dynamically innovate resource-leveling customer service for state of the art customer service.";
const paragraph3 : string = "Gigamatch was founded in 2022 by Richard Franzén and Niklas WhatsHisName.";


export default function AboutPage()
{
  return (
    <SupportPage text={title}>

      <ParagraphContainer>
        <Typography variant="body1">{paragraph1}</Typography>
      </ParagraphContainer>

      <ParagraphContainer>
        <Typography variant="body1">{paragraph2}</Typography>
      </ParagraphContainer>

      <ParagraphContainer>
        <Typography variant="body1">{paragraph3}</Typography>
      </ParagraphContainer>

      <ImageContainer src="/img/ricnik.jpg" alt="Richard and Niklas" />

    </SupportPage>
  );
}
