import { Typography } from "@mui/material";
import SupportPage, { ParagraphContainer } from "./SupportPage"


const title : string = "How we use cookies";
const paragraph1 : string = "We use as few cookies as possible.";
const paragraph2 : string =
  "Those we do use are there to ensure that our site works correctly. " +
  "They handle essential functionality, and cannot be turned off. " +
  "We do not use cookies to track you, and we never share data from our cookies with third parties. " +
  "Just like it should be everywhere.";
const paragraph3 : string = "By using Gigamatch you agree to this cookie policy.";
const footnote : string = "Note: for some functionality we use Local Storage instead of cookies, the purpose is however the same as for cookies.";

export default function CookiePolicyPage()
{
  return (
    <SupportPage text={title}>
      <ParagraphContainer>
        <Typography variant="body1">{paragraph1}</Typography>
      </ParagraphContainer>
      <ParagraphContainer>
        <Typography variant="body1">{paragraph2}</Typography>
      </ParagraphContainer>
      <ParagraphContainer>
        <Typography variant="body1">{paragraph3}</Typography>
      </ParagraphContainer>
      <ParagraphContainer>
        <Typography variant="body2">{footnote}</Typography>
      </ParagraphContainer>
    </SupportPage>
  );
}
