import { createContext, useContext, useState } from 'react';

import { UserSession } from './UserSession';
import { loadUserSession, storeSessionUserName, storeSessionToken} from './SessionPersistence';
import { ChildrenProps } from '../type/BaseTypes';


// A user session context holds a UserSession and functions for setting the
// inidividual values of the user session.
export type UserSessionContext =
{
  userSession: UserSession;
  setUserName: (userName: string) => void;
  setToken: (token: string) => void;
  setMessage: (message: string | null) => void;
  clear: () => void;
};


// Create a context of type UserSessionContextType with an initial 'undefined' value,
// this value will be replaced by the provider.
const userSessionContext : React.Context<UserSessionContext | undefined> =
  createContext<UserSessionContext | undefined>(undefined);


export function useSessionContext() : UserSessionContext
{
	let context = useContext(userSessionContext);
	if (context === undefined)
		throw new Error('useSessionContext() called outside UserSessionProvider');

	return context;
}


export const UserSessionProvider = ({ children }: ChildrenProps) =>
{
  // The current user session, if any. The initial value is loaded from session storage.
  const [userSession, setUserSession] = useState<UserSession>(loadUserSession());

  // Set the session's user name.
  const setSessionUserName = (newUserName: string) => {
    storeSessionUserName(newUserName);
    setUserSession({...userSession, userName: newUserName});
  };

  // Set the session's token.
  const setSessionToken = (newToken: string) => {
    storeSessionToken(newToken);
    setUserSession({...userSession, token: newToken});
  };

  // Set the session's message. Pass null to clear the message.
  const setSessionMessage = (newMessage: string | null) => {
    setUserSession({...userSession, message: newMessage});
  };

  // Clear the session, effectively logging out.
  const clearSession = () => {
    storeSessionUserName(null);
    storeSessionToken(null);
    setUserSession({userName: null, token: null, message: null});
  };

  return (
		<userSessionContext.Provider
      value={
        {
          userSession: userSession,
          setUserName: setSessionUserName,
          setToken: setSessionToken,
          setMessage: setSessionMessage,
          clear: clearSession
        }
      }
    >
			{children}
		</userSessionContext.Provider>
	);
};
