import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

import {HomePageSectionContainer, HomePageSectionTitleContainer} from "./HomePageSection";
import CalendarDateLogo from '../../component/image/CalendarDateLogo';


const sectionTitle : string = "Upcoming events";

const events : EventItemProps[] =
[
  {
    id: "1",
    date: "21",
    month: "October",
    time: "21:00",
    title: "Five Blind Mice play at The Pavillion",
    text: "The Pavillion on Hornsgatan 32 in Stockholm features indie band Five Blind Mice this Friday. Doors open at 20:00.",
  },
  {
    id: "2",
    date: "22",
    month: "October",
    time: "23:00",
    title: "All-nighter with DJ DaBaus",
    text: "High energy dance mania when DJ DaBaus plays at Electric Apple Club, Norra Bantorget. The music stops when last man standing falls.",
  },
  {
    id: "3",
    date: "1",
    month: "November",
    time: "20:30",
    title: "Quiz with trobadour at Ölstopet",
    text: "Come test your music trivia knowledge at pub Ölstopet on Avenyn in Göteborg. Local trobadour Glenn Möller entertains with tricky questions and sing-along-songs. No entrance fee.",
  },
  {
    id: "4",
    date: "6",
    month: "November",
    time: "12:00",
    title: "The Gigamatch fall contest starts",
    text: "Check in on the Gigamatch web site to enter the fall contest. Collect QR codes at select gigs throughout the fall and win sensational prizes.",
  }
];

// The event items are displayed inside a container
const EventItemsContainer = styled('div')
(
  {
    maxWidth: '700px',
  }
);

// An event item is displayed inside a container with margins
const EventItemContainer = styled('div')
(
  ({ theme }) => ({
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    backgroundColor: '#fafafa',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.dark,
    borderRadius: 7,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  })
);

const CalendarIconContainer = styled('div')
(
  {
    minWidth: '60px',
    minHeight: '60px',
    maxWidth: '60px',
    maxHeight: '60px',
  }
);

const EventInfoContainer = styled('div')
(
  ({ theme }) => ({
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  })
);

type EventItemProps = {
  id: string;
  date: string;
  month: string;
  time: string;
  title: string;
  text: string;
}

function EventItem( eventItem : EventItemProps )
{
  return (
    <EventItemContainer>
      <CalendarIconContainer>
        <CalendarDateLogo />
      </CalendarIconContainer>
      <EventInfoContainer>
        <Typography variant="h6">{eventItem.date}&nbsp;{eventItem.month}&nbsp;{eventItem.time}&nbsp;</Typography>
        <Typography variant="subtitle1">{eventItem.title}</Typography>
        <Typography variant="subtitle2">{eventItem.text}</Typography>
      </EventInfoContainer>
    </EventItemContainer>
  );
}

export default function UpcomingEventsSection()
{
  return (
    <HomePageSectionContainer>

      <HomePageSectionTitleContainer>
        <Typography variant="h2">{sectionTitle}</Typography>
      </HomePageSectionTitleContainer>

      <EventItemsContainer>
        {events.map((evt) => (<EventItem key={evt.id} {...evt} /> ))}
      </EventItemsContainer>

    </HomePageSectionContainer>
  );
}