// Properties for the Gigamatch logo element
type GmLogoProps = {
  logoHeight: string;
}

export default function GmLogo({ logoHeight } : GmLogoProps) {
  return (
    <img
      style={ {height: logoHeight} }
      src="/img/gmlogo.png"
      alt="gigamatch logo"
    />
  );
}

export function GmLogoTextHorizontal({ logoHeight } : GmLogoProps) {
  return (
    <img
      style={ {height: logoHeight} }
      src="/img/gmlogo-with-text-hz.svg"
      alt="gigamatch logo"
    />
  );
}

export function GmLogoTextVertical({ logoHeight } : GmLogoProps) {
  return (
    <img
      style={ {height: logoHeight} }
      src="/img/gmlogo-with-text-vt.svg"
      alt="gigamatch logo"
    />
  );
}
