import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';

import {HomePageSectionContainer, HomePageSectionTitleContainer} from "./HomePageSection";


const newsTitle : string = "The latest buzz";
const newsItemTitle1 : string = "Dead Engineers on tour";
const newsItemText1 : string = "Death metal band Dead Engineers will be touring in November. Prepare for an evening of distorted guitars and ridiculously many decibels. Check the Gigs calendar to make sure you catch them when they pass by in your neighbourhood.";
const newsItemTitle2 : string = "Jazz Wednesdays at Trumpetpalatset";
const newsItemText2 : string = "Starting on October 26th, music pub Trumpetpalatset on Södermalm in Stockholm will host jazz events. First up will be legandary pianist Nisse 'Fat Cat' Almqvist. Free entrance. Happy hour between 19 and 20.";
const newsItemTitle3 : string = "Win fun prizes";
const newsItemText3 : string = "During November and December, Gigamatch will host a contest. Scan QR codes at the special contest gigs and solve the puzzle contained within them.";


// The new items container displays a row of news item containers
const NewsItemsContainer = styled('div')
(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
  })
);

// A news item is displayed inside a container with a width varying with
// the screen size
const NewsItemContainer = styled('div')
(
  ({ theme }) => ({
    width: '320px',
    [theme.breakpoints.down('lg')]: {
//      width: 'calc(100vw / 3 - 50px)',
      width: '280px',
    },
    [theme.breakpoints.down('md')]: {
      width: '250px',
    },
    minHeight: '300px',
    backgroundColor: '#fafafa',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.dark,
    borderRadius: 7,
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  })
);

// A news image has a fixed height and is centered inside its container
const NewsImageContainer = styled('div')
(
  {
    height: 100,
    textAlign: 'center',
  }
);

// The title's margin to the image
const NewsTitleContainer = styled('div')
(
  ({ theme }) => ({
    marginTop: theme.spacing(2),
  })
);

// The text's margin to the title
const NewsTextContainer = styled('div')
(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
  })
);


type NewsItemProps = {
  title: string;
  text: string;
  imagePath: string;
}

function NewsItem( {title, text, imagePath} : NewsItemProps )
{
  return (
    <NewsItemContainer>
      <NewsImageContainer>
        <img src={imagePath} style={{height: '100%'}} alt="news illustration"/>
      </NewsImageContainer>
      <NewsTitleContainer>
        <Typography variant="h5">{title}</Typography>
      </NewsTitleContainer>
      <NewsTextContainer>
        <Typography variant="body1">{text}</Typography>
      </NewsTextContainer>
    </NewsItemContainer>
  );
}

export default function NewsSection()
{
  return (
    <HomePageSectionContainer>
      <HomePageSectionTitleContainer>
        <Typography variant="h2">{newsTitle}</Typography>
      </HomePageSectionTitleContainer>
      <NewsItemsContainer>
        <NewsItem title={newsItemTitle1} text={newsItemText1} imagePath="/img/guitar.jpg"/>
        <NewsItem title={newsItemTitle2} text={newsItemText2} imagePath="/img/club.jpg"/>
        <NewsItem title={newsItemTitle3} text={newsItemText3} imagePath="/img/competition.jpg"/>
      </NewsItemsContainer>
    </HomePageSectionContainer>
  );
}
