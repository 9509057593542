import { useEffect } from "react";

import ApplicationBar from '../../component/common/AppBar';
import Footer from '../../component/common/Footer';
import { ChildrenProps } from '../../type/BaseTypes';


// Common page layout and functionality.
// A base page has a sticky application bar with the navigation menu and a
// footer with links to support pages and external resources.
// A base page scrolls to top on first render.
export default function BasePage({children}: ChildrenProps)
{
  // Scroll to top on first render
  useEffect(() => { window.scrollTo(0, 0); }, []);

  return (
    <>
      <ApplicationBar/>
      {children}
      <Footer/>
    </>
  );
}
