import { Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import SupportPage, { ParagraphContainer } from "./SupportPage"


const title : string = "How does it work?";
const defintion1 : string = "Create a profile";
const explanation1 : string =
  "Those we do use are there to ensure that our site works correctly. " +
  "They handle essential functionality, and cannot be turned off. " +
  "We do not use cookies to track you, and we never share data from our cookies with third parties. " +
  "Just like it should be everywhere.";
  const defintion2 : string = "Search for artists or venues";
  const defintion3 : string = "Agree on gig";
  const defintion4 : string = "Create unforgettable moments";


const SectionsContainer = styled('div')
(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
  })
);

const SectionContainer = styled('div')
(
  ({ theme }) => ({
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  })
);

const SectionDefinitionContainer = styled('div')
(
  ({ theme }) => ({
    width: '200px',
    minHeight: '100px',
    backgroundColor: '#fafafa',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.primary.dark,
    borderRadius: 4,
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  })
);

const SectionExplanationContainer = styled('div')
(
  ({ theme }) => ({
    maxWidth: '400px',
    marginLeft: theme.spacing(6),
  })
);

type SectionProps = {
  index: string;
  definition: string;
  explanation: string;
}

function Section({index, definition, explanation} : SectionProps)
{
  return (
    <SectionContainer>
      <SectionDefinitionContainer>
        <Typography variant="h6">{index}</Typography>
        <Typography variant="h6">{definition}</Typography>
      </SectionDefinitionContainer>
      <SectionExplanationContainer>
        <Typography>{explanation}</Typography>
      </SectionExplanationContainer>
    </SectionContainer>
  );
}


export default function HowItWorksPage()
{
  return (
    <SupportPage text={title}>
        <SectionsContainer>
          <Section index="I" definition={defintion1} explanation={explanation1}/>
          <Section index="II" definition={defintion2} explanation={explanation1}/>
          <Section index="III" definition={defintion3} explanation={explanation1}/>
          <Section index="IV" definition={defintion4} explanation={explanation1}/>
        </SectionsContainer>
    </SupportPage>
  );
}
