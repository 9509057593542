import React from 'react';
import { AppBar, Button, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';

import { useSessionContext } from '../../session/SessionContext';
import { GmLogoTextHorizontal} from '../image/GmLogo';
import InternalLink from '../link/InternalLink';


const navigationMenuItems = ['Artists', 'Venues', 'Fans', 'Gigs', 'News'];
const settingsMenuItems = ['Profile', 'Account', 'Logout'];


// Style for the entire header, a row of navigation items
const HeaderContainer = styled('div')
(
  ({ theme }) => ({
    color: "black",
    backgroundColor: theme.palette.primary.main,
    maxWidth: theme.breakpoints.values.xl,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between"
  })
);

// Style for the logo and company name
const LogoContainer = styled('div')
(
  {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  }  
);

// Style for the compact navigation menu, only shown on xs and sm screens
const CompactNavigationMenuContainer = styled('div')
(
  ({ theme }) => ({
    display: "flex",
    [theme.breakpoints.up('md')]: {
      display: "none",
    },
    flexGrow: 0,
  })
);

// Style for the wide navigation menu, shown on all but xs and sm screens
const WideNavigationMenuContainer = styled('div')
(
  ({ theme }) => ({
    paddingLeft: theme.spacing(4),
    display: "flex",
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
    flexGrow: 1,
  })
);

// Style for the login container
const LoginContainer = styled('div')
(
  {
    flexGrow: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  }
);

// Style for the settings menu
const SettingsMenuContainer = styled('div')
(
  {
    flexGrow: 0,
  }
);


// Render the logo as a link to the home page
function Logo()
{
  return (
    <InternalLink path="/"> 
      <LogoContainer>
        <GmLogoTextHorizontal logoHeight="64px"/>
      </LogoContainer>
    </InternalLink>
  );
}

// Properties for elements with a close action
type CloseActionProps = {
  closeAction: () => void;
}

// Properties for menu elements
type MenuProps = CloseActionProps & {
  anchorElement: HTMLElement | null;
  openMenuAction: React.MouseEventHandler<HTMLButtonElement>;
}


// Render the compact navigation menu, which is a menu icon that displays the
// menu items when clicked
function CompactNavigationMenu( {anchorElement, openMenuAction, closeAction} : MenuProps )
{
  return (
    <CompactNavigationMenuContainer>
       <Tooltip title="Navigation menu">
        <IconButton
          size="large"
          aria-label="app bar menu"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={openMenuAction}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElement}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElement)}
        onClose={closeAction}
        sx={{ display: { xs: 'block', md: 'none' }} }
      >
        {navigationMenuItems.map((item) => (
          <MenuItem key={item} onClick={closeAction}>
            <Typography variant="button">{item}</Typography>
          </MenuItem>
        ))}
      </Menu> 
    </CompactNavigationMenuContainer>
  );
}


// Render the wide navigation menu, which is a sequence of text buttons
function WideNavigationMenu( { closeAction } : CloseActionProps)
{
  return (
    <WideNavigationMenuContainer>
      {navigationMenuItems.map((item) => (
        <Button
          key={item}
          onClick={closeAction}
          sx={{ ml: 3, color: 'inherit', display: 'block' }}
        >
          {item}
        </Button>
      ))}
    </WideNavigationMenuContainer>
  );
}


// Render the login and register buttons.
function LoginSection()
{
  const loginLabel = 'Login';
  const registerLabel = 'Register';
  const buttonStyle = {  ml: 3, color: 'inherit', display: 'block' };

  return (
    <LoginContainer>
      <Button key={loginLabel} onClick={() => {}} sx={buttonStyle}>{loginLabel}</Button>
      <Button key={registerLabel} onClick={() => {}} sx={buttonStyle}>{registerLabel}</Button>
    </LoginContainer>
  )
}


// Render the settings menu
function SettingsMenu()
{
  // State variable for the anchor of the settings menu. A null variable means the menu isn't shown
  const [settingsAnchor, setSettingsAnchor] = React.useState<null | HTMLElement>(null);

  // Open the settings menu
  const openSettingsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setSettingsAnchor(event.currentTarget);
  };

  // Close the settings menu
  const closeSettingsMenu = () => {
    setSettingsAnchor(null);
  };

  return (
    <SettingsMenuContainer>
      <Tooltip title="Open settings">
        <IconButton onClick={openSettingsMenu} sx={{ p: 0 }}>
          <PersonIcon sx={{ width: 48, height: 48 }}/>
        </IconButton>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={settingsAnchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(settingsAnchor)}
        onClose={closeSettingsMenu}
      >
        {settingsMenuItems.map((item) => (
          <MenuItem key={item} onClick={closeSettingsMenu}>
            <Typography variant="button">{item}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </SettingsMenuContainer>
  );
}


// Render the settings section, which is the login section if there is no active session,
// otherwise it is the settings menu.
function SessionSection()
{
  const sessionContext = useSessionContext();
  return sessionContext.userSession.token === null ? <SettingsMenu/> : <LoginSection/>;
}


// Render the application's top bar
export default function ApplicationBar()
{
  // State variables for the anchor of the compact navigation menu.
  // A null variable means the menu isn't shown.
  const [navigationAnchor, setNavigationAnchor] = React.useState<null | HTMLElement>(null);

  // Open the compact navigation menu (only shown on small screens)
  const openNavigationMenu = (event: React.MouseEvent<HTMLElement>) => {
    setNavigationAnchor(event.currentTarget);
  };

  // Close the compact navigation menu. May be triggered by clicks in both the compact and
  // the wide navigation menu.
  const closeNavigationMenu = () => {
    setNavigationAnchor(null);
  };

  return (
    <AppBar elevation={0} position="sticky">
      <HeaderContainer>
        <CompactNavigationMenu
          anchorElement={navigationAnchor}
          openMenuAction={openNavigationMenu}
          closeAction={closeNavigationMenu} />
        <Logo/>
        <WideNavigationMenu closeAction={closeNavigationMenu} />
        <SessionSection />
      </HeaderContainer>
    </AppBar>
  );
};
